<template>
  <div class="card">
    <div class="card-body p-0">
      <div class="row w-100 m-0">
        <div class="col-12 tenders-list p-0">
          <div class="d-flex p-3">
            <div class="col-2 p-0 btn-group dropright show p-0">
              <button
                  type="button"
                  @click="() => {$router.push('/organizator/timeless-tenders/add')}"
                  class="btn btn-primary"
              >
                <i class="fas fa-plus mr-1"></i>
                <span>Ավելացնել անժամկետ տենդերներ</span>
              </button>
            </div>
          </div>
          <div class="d-flex flex-wrap gap-2 p-3">
            <div v-for="tender in tenders" :key="tender.id" class="d-flex flex-column tender-item-block p-2">
              <div>
                <img :src="`${backendUrl}/uploads/${tender.pictures[0]}`">
              </div>
              <p class="name">
                {{tender.name}}
              </p>
              <span class="code">
                {{tender.code}}
              </span>
              <div class="d-flex flex-row justify-content-between">
                <p class="price">
                  {{tender.market_price}}դր․
                </p>
                <div>
                  <span v-tooltip="'Խմբագրել'" @click="() => {$router.push(`/organizator/timeless-tenders/edit/${tender.id}`)}">
                    <svg class="mr-2" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95199 7.61638L13.5648 2L14.5076 2.9434L8.89479 8.55978L7.95199 7.61638ZM3.84113 12.6213L12.241 12.6658L12.2766 7.3291H13.6188V12.4434C13.6188 13.2973 12.9165 13.9999 12.0632 13.9999H4.06335C3.20114 13.9999 2.50781 13.2973 2.50781 12.4434V4.4384C2.50781 3.58453 3.21002 2.88187 4.06335 2.88187H8.46328V4.24272L3.88557 4.21604L3.84113 12.6213Z" fill="#006BE6"/>
                    </svg>
                  </span>
                    <span v-tooltip="'Հեռացնել'" @click="() => deleteTender(tender.id)">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.11768 0H11.3877V1.5H5.11768V0ZM0.507812 3.29004H16.0078V4.79004H14.5078V15.59C14.5052 16.0534 14.32 16.497 13.9923 16.8246C13.6647 17.1522 13.2211 17.3374 12.7578 17.34H3.75781C3.29368 17.34 2.84856 17.1557 2.52038 16.8275C2.19219 16.4993 2.00781 16.0542 2.00781 15.59V4.79004H0.507812V3.29004ZM3.50781 15.79L12.9578 15.84L12.9978 4.79004H3.50781V15.79Z" fill="#E02232"/>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tender-rows-modal />
    <report-modal />
    <members-modal />
  </div>
</template>
<script>
  import ReportModal from './modals/ReportModal'
  import MembersModal from './modals/MembersModal'
  import TenderRowsModal from '../../participant/tenders/modals/TenderRowsModal'

  export default {
    components: { ReportModal, MembersModal, TenderRowsModal },
    data() {
      return {
        dateNow: new Date(),
        backendUrl: process.env.VUE_APP_URL,
        tenders: [],
        columns: [
          'password',
          'title',
          'products',
          'opening_finish_date',
          'price',
          'region',
          'type',
          'invitation',
          'edit',
          'participants_count'
        ],
      }
    },
    computed: {
      currentPlan() {
        return this.$store.getters['procurement/currentPlan']
      },
      locale() {
        return this.$store.getters['user/locale']
      }
    },
    mounted() {
      this.$client.get('manager/timeless-tenders').then(({data}) => {
        console.log('data', data.data)
        this.tenders = data.data;
      })
    },
    methods: {
      deleteTender(id){
        this.$client.delete(`timeless-tenders/${id}`).then(({data}) => {
          this.$client.get('manager/timeless-tenders').then(({data}) => {
            console.log('data', data.data)
            this.tenders = data.data;
          })
        })
      }
    },
  }
</script>
<style scoped>
.tender-item-block{
  max-width: calc(100% / 5);
}
.tender-item-block *{
  max-width: 100%;
}
.tender-item-block img{
  border-radius: 6px;
  height: n00px;
}
.tender-item-block p{
  border-radius: 6px;
}
.tender-item-block .name{
  font-size: 22px;
  font-weight: 500;
}
.tender-item-block .code{
  font-size: 14px;
  font-weight: 300;
  color: #595E6B;
}
.tender-item-block .price{
  font-size: 18px;
  font-weight: 600;
}
</style>

